<div *ngIf="items.length > 0">
  <form [formGroup]="form">
    <h2 class="mb-2 font-bold">{{ label }}</h2>
    <div
      *ngFor="let item of $any(form.controls.items).controls; let i = index"
      formArrayName="items"
    >
      <label class="inline-flex items-start text-sm">
        <input
          type="checkbox"
          class="text-accent-base dark:text-accent-dark focus:border-accent-base focus:ring-accent-base h-5 w-5 flex-shrink-0 rounded border-gray-300 shadow-sm focus:border-opacity-20 focus:ring focus:ring-opacity-20 focus:ring-offset-0"
          [formControlName]="i"
          (change)="onSubmit()"
        />
        <span class="ml-2">{{ items[i].name }}</span>
      </label>
    </div>
  </form>
</div>
