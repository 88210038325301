import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { FeaturedCategoriesComponent } from '@lysties/classification/featured';
import { HighlightsCarouselComponent } from '@lysties/highlights/carousel';
import { RecentReviewsComponent } from '@lysties/reviews/recent';
import { MetadataService } from '@lysties/shared/seo';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    HighlightsCarouselComponent,
    RecentReviewsComponent,
    FeaturedCategoriesComponent,
  ],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  constructor(metadataService: MetadataService) {
    metadataService.updateMetadata({
      title: $localize`Restaurants, Fast-Foods, Lounges`,
      description: $localize`User Reviews and Recommendations of Best Restaurants, Fast-foods, Lounges and More at Lysties`,
      keywords: [
        $localize`restaurants`,
        $localize`fast-foods`,
        $localize`lounges`,
        $localize`businesses`,
        $localize`reviews`,
      ],
      image: '/icons/icon-512x512.png',
    });
  }
}
