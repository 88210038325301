import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterModule,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
  ProgressBarComponent,
  ToastrsComponent,
  ToastrService,
} from '@lysties/shared/ui';

@Component({
  standalone: true,
  imports: [RouterModule, ProgressBarComponent, ToastrsComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  loading = false;
  initialLoad = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platform: string,
    router: Router,
    private swUpdate: SwUpdate,
    private toastr: ToastrService,
  ) {
    // Listen to router events in order to set loading flag to true or false.
    router.events.subscribe({
      next: (event) => {
        if (event instanceof RouteConfigLoadStart) {
          this.loading = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loading = false;
        } else if (event instanceof NavigationEnd) {
          this.checkAndRemoveOverlay(platform);
        }
      },
    });
  }

  private checkAndRemoveOverlay(platform: string) {
    if (this.initialLoad && isPlatformBrowser(platform)) {
      this.document.getElementById('loader')?.classList.add('hidden');
      this.initialLoad = false;
    }
  }
}
