import { Injectable } from '@angular/core';
import { Toastr, ToastrActions } from './toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  private toastrs: Toastr[] = [];

  getToastrs(): Toastr[] {
    return this.toastrs;
  }

  add(
    color: 'success' | 'error' | 'warning' | 'info',
    title = '',
    message = '',
    dismissible = true,
    duration = 5000,
    actions?: ToastrActions,
  ): void {
    const existing = this.toastrs.find(
      (toastr: Toastr) => toastr.title === title && toastr.message === message,
    );
    const toastr: Toastr = {
      color,
      title,
      message,
      dismissible,
      duration,
      actions,
    };

    if (!existing) {
      this.toastrs.push(toastr);
    }

    if (duration !== -1) {
      setTimeout(() => {
        this.remove(toastr);
      }, duration);
    }
  }

  success(
    message: string,
    title = '',
    dismissible = true,
    duration = 5000,
    actions?: ToastrActions,
  ): void {
    this.add('success', title, message, dismissible, duration, actions);
  }

  error(
    message: string,
    title = '',
    dismissible = true,
    duration = 5000,
    actions?: ToastrActions,
  ): void {
    this.add('error', title, message, dismissible, duration, actions);
  }

  info(
    message: string,
    title = '',
    dismissible = true,
    duration = 5000,
    actions?: ToastrActions,
  ): void {
    this.add('info', title, message, dismissible, duration, actions);
  }

  warning(
    message: string,
    title = '',
    dismissible = true,
    duration = 5000,
    actions?: ToastrActions,
  ): void {
    this.add('warning', title, message, dismissible, duration, actions);
  }

  remove(toastr: Toastr): void {
    const index = this.toastrs.findIndex((t: Toastr) => t === toastr);
    this.toastrs.splice(index, 1);
  }
}
