import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  booleanAttribute,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '../card/card.component';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'ui-alert',
  standalone: true,
  imports: [CommonModule, CardComponent],
  templateUrl: './alert.component.html',
})
export class AlertComponent extends BaseComponent implements AfterViewInit {
  @Input() color: 'info' | 'success' | 'warning' | 'error' | 'accent' = 'info';
  @Input({ transform: booleanAttribute }) borders = true;
  @Input() classes = '';

  constructor(elementRef: ElementRef<HTMLElement>) {
    super(elementRef);

    // Default alert classes
    this.addToClassList('block');
  }

  ngAfterViewInit() {
    if (this.classes) {
      this.addToClassList(this.classes);
    }
  }
}
