import { OverlayModule } from '@angular/cdk/overlay';
import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
} from 'rxjs';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  bootstrapGeoAlt,
  bootstrapArrowLeft,
  bootstrapSearch,
} from '@ng-icons/bootstrap-icons';

import { BusinessService } from '@lysties/businesses/data-access';
import { Category, CategoryService } from '@lysties/classification/data-access';
import { Results } from '@lysties/common/api';
import {
  City,
  CityService,
  Location,
  LocationService,
  Neighborhood,
  NeighborhoodService,
} from '@lysties/locations/data-access';
import {
  AutocompleteComponent,
  AutocompleteOption,
  ButtonComponent,
} from '@lysties/shared/ui';

/**
 * Search bar component.
 * Used to search for businesses.
 */
@Component({
  selector: 'app-businesses-search-bar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgIconComponent,
    ButtonComponent,
    OverlayModule,
    AutocompleteComponent,
  ],
  templateUrl: './businesses-search-bar.component.html',
  styles: ':host {display: block; width: 100%;}',
  viewProviders: [
    provideIcons({
      bootstrapArrowLeft,
      bootstrapGeoAlt,
      bootstrapSearch,
    }),
  ],
})
export class BusinessesSearchBarComponent implements OnInit, OnDestroy {
  mobileQuery?: MediaQueryList;
  loading = false;
  isActive = false;
  suggestCategories = false;
  suggestLocations = false;

  categories: AutocompleteOption<Category>[] = [];
  categoriesQuery$ = new Subject<string>();
  selectedCategoryOption!: AutocompleteOption<Category>;
  categoriesFocused = false;

  locations: AutocompleteOption<Location>[] = [];
  locationsQuery$ = new Subject<string>();
  selectedLocationOption!: AutocompleteOption<Location>;
  locationsFocused = false;
  defaultLocationSlug = '';

  form = new FormGroup({
    category: new FormControl<string>(''),
    location: new FormControl<string>(''),
  });

  private mobileQueryListener: () => void;
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private route: ActivatedRoute,
    private router: Router,
    private businessService: BusinessService,
    private categoryService: CategoryService,
    private locationService: LocationService,
    private neighborhoodService: NeighborhoodService,
    private cityService: CityService,
  ) {
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    if (isPlatformBrowser(platformId)) {
      this.mobileQuery = media.matchMedia('(max-width: 768px)');
      this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    }
  }

  ngOnInit(): void {
    this.businessService
      .getLoading()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loading: boolean) => (this.loading = loading));

    this.loadCategory();
    this.loadLocation();
    this.watchCategories();
    this.watchCategory();
    this.watchLocations();
    this.watchLocation();

    this.locationService
      .getLocation()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (location: Location) => {
          this.defaultLocationSlug = location.neighborhood.slug
            ? location.neighborhood.slug
            : location.neighborhood.city.slug;
          this.locationsQuery$.next(this.defaultLocationSlug);
        },
      });
  }

  /**
   * Submits the search form.
   */
  onSubmit(): void {
    if (!this.selectedLocationOption) return;
    const neighborhood = this.selectedLocationOption.value.neighborhood;
    const queryParams = {
      cat: this.selectedCategoryOption?.value?.slug,
      loc: neighborhood.slug ? neighborhood.slug : undefined,
      city: neighborhood.slug ? undefined : neighborhood.city.slug,
      subcat: undefined,
      attrs: undefined,
    };

    this.businessService.nextSearchedLocation(
      this.selectedLocationOption.value,
    );
    if (this.selectedCategoryOption) {
      this.businessService.nextSearchedCategory(
        this.selectedCategoryOption.value,
      );
    }

    this.isActive = false;

    this.router.navigate(['/businesses'], {
      queryParamsHandling: 'merge',
      queryParams,
    });
  }

  /**
   * Marks the form as activated for mobile devices.
   * Also selects the categories input.
   */
  onActivate(initialValue: string): void {
    this.isActive = true;
    this.suggestCategories = true;
    this.categoriesFocused = true;
    if (this.selectedCategoryOption) {
      this.onSearchCategories(this.selectedCategoryOption.value.slug);
    } else {
      this.onSearchCategories(initialValue);
    }
  }

  onSuggestLocations(initialValue: string): void {
    this.suggestLocations = true;
    this.locationsFocused = true;
    const neighborhood = this.selectedLocationOption?.value?.neighborhood;
    const slug = neighborhood?.slug
      ? neighborhood?.slug
      : neighborhood?.city.slug;
    this.onSearchLocations(slug || initialValue);
  }

  /**
   * Marks the form as deactivated for mobile devices.
   */
  onDeactivate(): void {
    if (this.isActive) {
      this.isActive = false;
    }
  }

  onSearchCategories(query: string): void {
    this.categoriesQuery$.next(query);
  }

  onSearchLocations(query: string): void {
    this.locationsQuery$.next(query);
  }

  onCategorySelected(option: AutocompleteOption<unknown>): void {
    this.selectedCategoryOption = option as AutocompleteOption<Category>;
    this.onSubmit();
  }

  onLocationSelected(option: AutocompleteOption<unknown>): void {
    this.selectedLocationOption = option as AutocompleteOption<Location>;
    this.locationService.changeLocation(this.selectedLocationOption.value);
    // Need to update categories to the new location
    this.onSearchCategories(this.selectedCategoryOption?.value.slug ?? '');
    this.onSubmit();
  }

  private watchCategories(): void {
    this.categoriesQuery$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300),
        switchMap((query: string) => this.searchCategories(query)),
      )
      .subscribe({
        next: (categories: AutocompleteOption<Category>[]) => {
          categories.unshift({
            label: $localize`All categories`,
            value: new Category(''),
          });
          this.categories = categories;
        },
      });
  }

  private searchCategories(
    query: string,
  ): Observable<AutocompleteOption<Category>[]> {
    const filters: { [key: string]: string } = { 'filter[search]': query };
    if (this.selectedLocationOption.value.neighborhood.slug) {
      filters['filter[loc]'] =
        this.selectedLocationOption.value.neighborhood.slug;
    }

    if (this.selectedLocationOption.value.neighborhood.city.slug) {
      filters['filter[city]'] =
        this.selectedLocationOption.value.neighborhood.city.slug;
    }

    return this.categoryService.list(filters).pipe(
      map((results: Results<Category>) => {
        return results.data.map((category) => ({
          label: category.name,
          value: category,
        }));
      }),
    );
  }

  private watchLocations(): void {
    this.locationsQuery$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query: string) => {
          return this.searchLocations(query);
        }),
      )
      .subscribe({
        next: (locations: AutocompleteOption<Location>[]) =>
          (this.locations = locations),
      });
  }

  private searchLocations(
    query: string,
  ): Observable<AutocompleteOption<Location>[]> {
    return this.locationService
      .list({
        'filter[search]': query,
      })
      .pipe(map((results: Results<Location>) => this.readLocations(results)));
  }

  private watchCategory(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (params: Params) => {
        this.loadCategory(params);
      },
    });
  }

  private loadCategory(params?: Params): void {
    params = params ?? this.route.snapshot.queryParams;

    if (params['cat']) {
      this.categoryService.retrieve(params['cat']).subscribe({
        next: (category: Category) => {
          this.selectedCategoryOption = {
            label: category.name,
            value: category,
          };

          this.businessService.nextSearchedCategory(category);
        },
      });
    }
  }

  private watchLocation(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (params: Params) => {
        this.loadLocation(params);
      },
    });
  }

  private loadLocation(params?: Params): void {
    params = params ?? this.route.snapshot.queryParams;

    if (params['loc']) {
      this.neighborhoodService.retrieve(params['loc']).subscribe({
        next: (neighborhood: Neighborhood) => {
          const location = new Location({ neighborhood: neighborhood });
          this.selectedLocationOption = {
            label: neighborhood.display(),
            value: location,
          };
          this.businessService.nextSearchedLocation(
            this.selectedLocationOption.value,
          );
        },
      });
    }

    if (params['city']) {
      this.cityService.retrieve(params['city']).subscribe({
        next: (city: City) => {
          const location = new Location({
            neighborhood: new Neighborhood({ city }),
          });
          this.selectedLocationOption = {
            label: location.display(),
            value: location,
          };
          this.businessService.nextSearchedLocation(city);
          this.locationService.changeLocation(location);
        },
      });
    }
  }

  private readLocations(
    results: Results<Location>,
  ): AutocompleteOption<Location>[] {
    const locations: AutocompleteOption<Location>[] = [];
    const cities: City[] = [];

    results.data.forEach((location) => {
      const city = location.neighborhood.city;

      if (!cities.some((c) => c.slug === city.slug)) {
        cities.push(city);
      }

      const existingLocation = locations.find(
        (loc) => loc.value.display() === location.display(),
      );
      if (!existingLocation) {
        const loc: AutocompleteOption<Location> = {
          label: location.display(),
          value: location,
        };
        if (
          location.neighborhood.slug === this.defaultLocationSlug &&
          !this.selectedLocationOption
        ) {
          this.selectedLocationOption = loc;
        }
        locations.push(loc);
      }
    });

    cities.forEach((city: City) => {
      const value = new Location({ neighborhood: new Neighborhood({ city }) });
      const location: AutocompleteOption<Location> = {
        label: city.display(),
        value,
      };
      if (
        city.slug === this.defaultLocationSlug &&
        !this.selectedLocationOption
      ) {
        this.selectedLocationOption = location;
      }
      locations.unshift(location);
    });

    return locations;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
