import { PageMetadata } from './page-metadata';

export const APP_NAME = 'Lysties';
export const DEFAULT_METADATA: PageMetadata = {
  title: $localize`Restaurants, Fast-Foods, Lounges`,
  description: $localize`Find the best restaurants, fast-foods, lounges and more in your area.`,
  keywords: ['restaurants', 'fast-foods', 'lounges', 'food', 'drinks'],
  type: 'website',
  image: '/assets/images/logo.png',
  imageAlt: APP_NAME,
};
