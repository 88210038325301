import { Business } from '@lysties/businesses/data-access';
import { ApiResource, Attribute, BaseResource } from '@lysties/common/api';
import { Photo } from '@lysties/photos/data-access';
import { User } from '@lysties/users/data-access';
import { ReviewRating } from './review-rating';

@ApiResource('Review')
export class Review extends BaseResource {
  @Attribute()
  businessName = '';

  @Attribute()
  businessSlug = '';

  @Attribute()
  rating = 0;

  @Attribute()
  comment = '';

  @Attribute()
  language = '';

  @Attribute(Date)
  created: Date = new Date();

  @Attribute()
  isAccepted = false;

  @Attribute(User)
  user: User = new User();

  @Attribute(Business)
  business?: Business;

  @Attribute(Photo)
  photos: Photo[] = [];

  @Attribute(ReviewRating)
  ratings: ReviewRating[] = [];

  constructor(init?: Partial<Review>) {
    super(init);

    Object.assign(this, init);
  }
}
