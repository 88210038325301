import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, Input, booleanAttribute } from '@angular/core';

import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { featherInstagram, featherFacebook } from '@ng-icons/feather-icons';
import {
  bootstrapLinkedin,
  bootstrapTiktok,
  bootstrapTwitterX,
} from '@ng-icons/bootstrap-icons';

import { ButtonComponent } from '@lysties/shared/ui';
import { RouterLink } from '@angular/router';

/**
 * Footer component.
 * Provides a standard footer with about and social links.
 */
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonComponent, NgIconComponent],
  templateUrl: './footer.component.html',
  viewProviders: [
    provideIcons({
      featherInstagram,
      bootstrapLinkedin,
      bootstrapTiktok,
      bootstrapTwitterX,
      featherFacebook,
    }),
  ],
})
export class FooterComponent {
  @Input({ transform: booleanAttribute }) lite = false;
  year = new Date().getFullYear();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  changeLanguage(lang: string) {
    this.document.cookie = `firebase-language-override=${lang}; path=/; max-age=31536000`;
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        let total = 0;
        for (const registration of registrations) {
          total += 1;
          registration.unregister().then(() => {
            this.addBypassParamAndReload();
          });
        }

        if (total === 0) {
          this.addBypassParamAndReload();
        }
      });
    } else {
      this.addBypassParamAndReload();
    }
  }

  private addBypassParamAndReload() {
    this.document.location.reload();
  }
}
