<ng-template #userMenuItems>
  @if (user) {
    <a
      [routerLink]="['/users', user.id]"
      i18n
      class="hover:bg-secondary-base dark:bg-dark-base focus:bg-secondary-base dark:hover:bg-dark-active dark:focus:bg-dark-active px-4 py-4 text-sm lg:py-2"
      (click)="toggleMenu()"
      >My Profile</a
    >
    <a
      [routerLink]="['/settings/profile']"
      i18n
      class="hover:bg-secondary-base dark:bg-dark-base focus:bg-secondary-base dark:hover:bg-dark-active dark:focus:bg-dark-active px-4 py-4 text-sm lg:py-2"
      (click)="toggleMenu()"
      >Settings</a
    >
  }
  <hr class="dark:border-dark-active hidden lg:block" />
  <button
    i18n
    class="hover:bg-secondary-base dark:bg-dark-base focus:bg-secondary-base dark:hover:bg-dark-active dark:focus:bg-dark-active flex px-4 py-4 text-sm lg:py-2"
    (click)="signOut()"
  >
    Sign Out
  </button>
</ng-template>

<ng-template #themeMenuItems>
  <button
    class="hover:bg-secondary-base dark:bg-dark-base focus:bg-secondary-base dark:hover:bg-dark-active dark:focus:bg-dark-active flex items-center whitespace-nowrap px-4 py-2 text-sm lg:mt-0 lg:text-left"
    (click)="changeTheme('light')"
  >
    <ng-icon name="matLightModeRound"></ng-icon>
    <span i18n class="ml-3">Light</span>
  </button>
  <button
    class="hover:bg-secondary-base dark:bg-dark-base focus:bg-secondary-base dark:hover:bg-dark-active dark:focus:bg-dark-active flex items-center whitespace-nowrap px-4 py-2 text-sm lg:mt-0 lg:text-left"
    (click)="changeTheme('dark')"
  >
    <ng-icon name="matDarkModeRound"></ng-icon>
    <span i18n class="ml-3">Dark</span>
  </button>
  <button
    class="hover:bg-secondary-base dark:bg-dark-base focus:bg-secondary-base dark:hover:bg-dark-active dark:focus:bg-dark-active flex items-center whitespace-nowrap px-4 py-2 text-sm lg:mt-0 lg:text-left"
    (click)="resetTheme()"
  >
    <ng-icon name="matDesktopMacRound"></ng-icon>
    <span i18n class="ml-3">System</span>
  </button>
</ng-template>

<header
  class="fixed left-0 right-0 top-0 z-10"
  ngClass
  [ngClass]="{
    'bg-transparent bg-gradient-to-b from-[#000000c4] to-[#FFFFFF00] text-white':
      transparent && !showMenu,
    'dark:bg-dark-base dark:border-dark-active bg-white dark:border-b':
      !transparent || showMenu,
    'border-light-active dark:border-dark-active border-b dark:border-b':
      !transparent && !showMenu,
    '': transparent,
  }"
>
  <div
    class="flex flex-col items-center px-4 py-4 lg:grid lg:grid-cols-12 lg:px-0"
    [ngClass]="{ 'justify-center': !lite }"
  >
    <div class="flex w-full flex-shrink-0 items-center lg:col-span-2 lg:pl-10">
      <a
        href="/"
        aria-hidden=""
        class="mr-4 flex items-center text-2xl font-semibold"
      >
        <img
          class="mr-2"
          [src]="
            transparent && !showMenu
              ? '/assets/images/logo-transparent.svg'
              : '/assets/images/logo-white.svg'
          "
          width="32"
          height="32"
          alt=""
        />
        <span>Lysties</span>
      </a>
      <button
        ui-button
        icon
        aria-label="Toggle navigation"
        i18n-aria-label
        class="ml-auto lg:hidden"
        (click)="toggleMenu()"
      >
        <ng-icon
          [name]="showMenu ? 'featherX' : 'featherMenu'"
          size="20"
          strokeWidth="3"
        ></ng-icon>
      </button>
    </div>
    @if (!lite) {
      <app-businesses-search-bar
        class="mr-auto mt-4 lg:col-span-6 lg:mt-0 xl:max-w-3xl"
      ></app-businesses-search-bar>
    }
    <nav
      class="fixed mt-4 flex flex-shrink-0 flex-col items-start lg:relative lg:mt-0 lg:flex-row-reverse lg:items-center lg:justify-end lg:pr-10"
      [ngClass]="{
        'lg:col-span-4': !lite,
        'lg:col-span-10': lite,
        'hidden lg:flex': !showMenu,
        'bottom-0 left-0 right-0 top-14 lg:top-0': showMenu,
        'dark:bg-dark-base bg-white': showMenu,
        'items-center justify-center': !user,
      }"
    >
      @if (user) {
        <button
          class="pointer-events-none flex items-center px-4 text-sm font-bold lg:pointer-events-auto lg:flex-row lg:px-0"
          [uiMenuTriggerFor]="userMenu"
          xPosition="after"
          yPosition="below"
          [yOffset]="10"
        >
          <img
            [src]="user.profile.avatar || '/assets/images/avatar.svg'"
            alt=""
            aria-hidden="true"
            class="h-10 w-10 rounded-full object-cover"
            width="40"
            height="40"
          />
          <span class="ml-2">{{ user.profile.display() }}</span>
        </button>
        <div class="flex flex-col lg:hidden lg:w-auto w-full">
          <ng-template [ngTemplateOutlet]="userMenuItems"></ng-template>
        </div>
        <ui-menu #userMenu>
          <ng-template [ngTemplateOutlet]="userMenuItems"></ng-template>
        </ui-menu>
      } @else {
        <img
          src="/assets/images/avatar.svg"
          alt=""
          aria-hidden="true"
          class="block lg:hidden"
          width="115"
          height="115"
        />
        <div
          class="flex flex-col-reverse items-center justify-center lg:flex-row"
        >
          <a
            routerLink="/signin"
            ui-flat-button
            full
            class="mb-4 w-full max-w-[165px] lg:mb-0 lg:mr-4 lg:w-auto"
            color="secondary"
            i18n
            [ngClass]="{ 'lg:hidden': lite }"
            (click)="toggleMenu()"
            >Sign In</a
          >
          <a
            routerLink="/signup"
            ui-flat-button
            color="primary"
            class="mb-4 mt-3 w-full max-w-[165px] lg:mb-0 lg:mt-0 lg:w-auto"
            i18n
            [ngClass]="{ 'lg:hidden': lite }"
            (click)="toggleMenu()"
            >Sign Up</a
          >
        </div>
      }
      <button
        ui-button
        class="lg:ml-auto"
        [ngClass]="{ 'lg:mr-4': !lite }"
        [uiMenuTriggerFor]="themeMenu"
        xPosition="before"
        aria-label="Change theme"
      >
        <ng-icon
          [name]="theme === 'dark' ? 'matDarkModeRound' : 'matLightModeRound'"
          size="24"
        ></ng-icon>
        <span class="ml-4 lg:hidden" i18n>Switch theme</span>
      </button>
      <ui-menu #themeMenu>
        <ng-template [ngTemplateOutlet]="themeMenuItems"></ng-template>
      </ui-menu>
    </nav>
  </div>
</header>
