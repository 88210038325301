<div class="group relative w-[80vw] sm:w-80">
  <ui-alert
    classes="shadow-lg hover:shadow-md transition-shadow ease-in-out duration-300"
    [borders]="false"
    [color]="color"
  >
    <div ngProjectAs="alert-title" class="flex w-full flex-1 items-center">
      <h3>{{ title }}</h3>
      @if (dismissible) {
        <button
          class="text-dark-active ml-auto flex items-center opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
          (click)="onClose()"
        >
          <ng-icon name="featherX" size="16" strokeWidth="2"></ng-icon>
        </button>
      }
    </div>
    <div ngProjectAs="alert-content">{{ message }}</div>
    <div ngProjectAs="alert-actions" class="flex w-full justify-end">
      @if (actions && actions.onCancel && actions.cancelText) {
        <button ui-stroked-button (click)="actions.onCancel()">
          {{ actions.cancelText }}
        </button>
      }

      @if (actions && actions.onConfirm && actions.confirmText) {
        <button
          ui-flat-button
          class="ml-3"
          color="secondary"
          (click)="actions.onConfirm()"
        >
          {{ actions.confirmText }}
        </button>
      }
    </div>
  </ui-alert>
</div>
