import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  booleanAttribute,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'input[ui-input],textarea[ui-input]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input.component.html',
})
export class InputComponent extends BaseComponent implements AfterViewInit {
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) prepend = false;
  @Input({ transform: booleanAttribute }) append = false;

  constructor(elementRef: ElementRef<HTMLElement>) {
    super(elementRef);

    this.addToClassList(
      'flex w-full rounded-md border border-secondary-active dark:border-dark-active bg-transparent px-3 py-2 lg:text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-base placeholder:text-dark-muted focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-accent-base dark:focus-visible:ring-white disabled:opacity-50',
    );
  }

  ngAfterViewInit(): void {
    if (this.disabled) {
      this.addToClassList('cursor-not-allowed opacity-50');
      this.getHostElement().setAttribute('disabled', 'disabled');
    }

    if (this.prepend) {
      this.addToClassList('pl-10');
    }

    if (this.append) {
      this.addToClassList('pr-10');
    }
  }
}
