import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'a[ui-link]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './link.component.html',
})
export class LinkComponent implements AfterViewInit {
  @Input() color: 'primary' | 'default' | 'accent' = 'default';

  constructor(private _elementRef: ElementRef<HTMLElement>) {
    // Button base classes:
    this.getHostElement().classList.add(
      'font-medium',
      'transition-all',
      'duration-300',
      'ease-in-out',
      'hover:underline',
      'focus:underline',
    );
  }

  ngAfterViewInit(): void {
    // Button color classes:
    if (this.color === 'primary') {
      this.getHostElement().classList.add('text-primary-base');
    } else if (this.color === 'accent') {
      this.getHostElement().classList.add(
        'text-accent-base',
        'dark:text-accent-dark',
      );
    } else {
      this.getHostElement().classList.add('underline');
    }
  }

  /**
   * Returns the host element.
   * @returns The host element.
   */
  private getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }
}
