<div
  role="alert"
  ui-card
  [borders]="borders"
  [ngClass]="{
    'text-white': color !== 'warning',
    'bg-info-base': color === 'info',
    'bg-success-default': color === 'success',
    'bg-danger-default': color === 'error',
    'bg-accent-base': color === 'accent',
    'bg-warning-default text-dark-base': color === 'warning',
  }"
>
  <div class="flex">
    <div
      [ngClass]="{
        'text-white': color !== 'warning',
        'text-dark-base': color === 'warning',
      }"
      #icon
    >
      <ng-content select="alert-icon"></ng-content>
    </div>
    <div
      class="flex flex-1 flex-col items-start space-y-1"
      [ngClass]="{ 'ml-4': icon.childNodes.length !== 0 }"
    >
      <div class="w-full font-bold">
        <ng-content select="alert-title"></ng-content>
      </div>
      <ng-content select="alert-content"></ng-content>
      <ng-content select="alert-actions"></ng-content>
    </div>
  </div>
</div>
