import { Injectable } from '@angular/core';
import { CreateMixin } from '@lysties/common/api';
import { PhotoPost } from './photo-post';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhotoPostService {
  private createMixin: CreateMixin<PhotoPost>;
  private url = '/photo-posts';

  constructor(http: HttpClient) {
    this.createMixin = new CreateMixin(http, this.url, PhotoPost);
  }

  create(data: Partial<PhotoPost>): Observable<PhotoPost> {
    return this.createMixin.create(data);
  }
}
