import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { catchError, of, throwError } from 'rxjs';

import { MaintenanceComponent } from '@lysties/blocks';
import { Router } from '@angular/router';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const dialog = inject(Dialog);
  const router = inject(Router);
  const allowedRoutes = ['/tos', '/privacy'];

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 503 && !allowedRoutes.includes(router.url)) {
        dialog.open(MaintenanceComponent, {
          disableClose: true,
          autoFocus: false,
        });
        return of();
      }
      return throwError(() => error);
    }),
  );
};
