import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { forkJoin, of } from 'rxjs';

import { AuthService } from '@lysties/auth/data-access';
import { themeResolver } from '@lysties/shared/theming';

export const appResolver: ResolveFn<unknown[]> = (route, state) => {
  const auth = inject(AuthService);
  const observables = [
    auth.isAuthenticated(true) ? auth.loadUser() : of(null),
    of(themeResolver(route, state)),
  ];

  return forkJoin(observables);
};
