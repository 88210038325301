@if (lite) {
  <footer class="py-5 text-sm text-white flex flex-wrap items-center">
    <a class="text-nowrap mr-5" routerLink="/about" i18n>About Lysties</a>
    <a class="text-nowrap mr-5" routerLink="/contact" i18n>Contact Us</a>
    <a class="text-nowrap mr-5" routerLink="/tos" i18n>Terms of Service</a>
    <a class="text-nowrap mr-3" routerLink="/privacy" i18n>Privacy Policy</a>
    <span class="mr-3">&middot;</span>
    <button
      class="leading-7 hover:text-white focus:text-white mr-5"
      i18n
      (click)="changeLanguage('en')"
      data-testid="change-language-en"
    >
      English
    </button>
    <button
      class="leading-7 hover:text-white focus:text-white mr-5"
      i18n
      (click)="changeLanguage('fr')"
      data-testid="change-language-fr"
    >
      French
    </button>
  </footer>
} @else {
  <footer
    class="dark:border-dark-active border-t bg-black py-[72px] text-sm text-dark-muted"
  >
    <div
      class="flex flex-col items-center px-4 py-4 lg:grid lg:grid-cols-12 lg:px-0"
    >
      <div class="w-full px-6 lg:col-span-8 lg:col-start-3 lg:px-0">
        <a
          href="/"
          aria-hidden=""
          class="inline-flex items-center text-2xl font-semibold text-white"
        >
          <img
            class="mr-2"
            src="/assets/images/logo-transparent.svg"
            width="32"
            height="32"
            alt=""
          />
          <span>Lysties</span>
        </a>
        <div
          class="dark:border-dark-active grid grid-cols-2 gap-4 border-b border-dark-muted py-10 md:grid-cols-3 lg:grid-cols-4"
        >
          <div class="col-span-1">
            <h3 class="mb-4 text-lg font-semibold text-white" i18n>About</h3>
            <ul>
              <li>
                <a
                  routerLink="/about"
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  >About Lysties</a
                >
              </li>
              <li>
                <a
                  routerLink="/contact"
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  >Contact Us</a
                >
              </li>
              <li>
                <a
                  routerLink="/tos"
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  >Terms of Service</a
                >
              </li>
              <li>
                <a
                  routerLink="/privacy"
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  >Privacy Policy</a
                >
              </li>
            </ul>
          </div>
          <div class="col-span-1">
            <h3 class="mb-4 text-lg font-semibold text-white" i18n>Cities</h3>
            <ul>
              <li>
                <a
                  routerLink="/businesses"
                  [queryParams]="{ city: 'yaounde' }"
                  [queryParamsHandling]="'replace'"
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  >Yaounde, CE</a
                >
              </li>
              <li>
                <a
                  routerLink="/businesses"
                  [queryParams]="{ city: 'douala' }"
                  [queryParamsHandling]="'replace'"
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  >Douala, LT</a
                >
              </li>
            </ul>
          </div>
          <div class="col-span-1">
            <h3 class="mb-4 text-lg font-semibold text-white" i18n>
              Languages
            </h3>
            <ul>
              <li>
                <button
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  (click)="changeLanguage('en')"
                  data-testid="change-language-en"
                >
                  English
                </button>
              </li>
              <li>
                <button
                  class="leading-7 hover:text-white focus:text-white"
                  i18n
                  (click)="changeLanguage('fr')"
                  data-testid="change-language-fr"
                >
                  French
                </button>
              </li>
            </ul>
          </div>
          <div class="col-span-1">
            <h3
              class="mb-4 text-lg font-semibold lg:text-right text-white"
              i18n
            >
              Connect with Us
            </h3>
            <div
              class="grid grid-cols-3 lg:grid-cols-4 gap-5 lg:justify-end justify-items-end text-white"
            >
              <a
                href="https://www.dklo.co/profile/lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on Dikalo"
              >
                <img
                  src="/icons/dikalo-32x32.png"
                  width="32"
                  height="32"
                  alt="Dikalo Icon"
                />
              </a>
              <a
                href="https://twitter.com/lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on Twitter"
              >
                <ng-icon name="bootstrapTwitterX" size="32"></ng-icon>
              </a>
              <a
                href="https://www.linkedin.com/company/lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on LinkedIn"
              >
                <ng-icon name="bootstrapLinkedin" size="32"></ng-icon>
              </a>
              <a
                href="https://www.tiktok.com/@lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on TikTok"
              >
                <ng-icon name="bootstrapTiktok" size="32"></ng-icon>
              </a>
              <a
                href="https://instagram.com/lystiescom"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on Instagram"
                class="lg:col-start-3"
              >
                <ng-icon name="featherInstagram" size="32"></ng-icon>
              </a>
              <a
                href="https://facebook.com/lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on Facebook"
              >
                <ng-icon name="featherFacebook" size="32"></ng-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="mt-10 flex justify-between">
          <p>&copy; {{ year }}. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
}
