import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptorFn } from '@angular/common/http';
import { inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';

export const httpLocaleInterceptor: HttpInterceptorFn = (req, next) => {
  const platformId = inject(PLATFORM_ID);
  const locale = isPlatformBrowser(platformId) ? inject(LOCALE_ID) : 'en';

  req = req.clone({
    setHeaders: {
      'Accept-Language': locale,
    },
  });

  return next(req);
};
