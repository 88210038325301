import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  provideZoneChangeDetection,
  isDevMode,
  inject,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
} from '@angular/router';
import {
  provideClientHydration,
  withNoHttpTransferCache,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withJsonpSupport,
} from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import {
  AUTH_ACCESS_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
  authInterceptor,
  GOOGLE_ACCOUNTS_CLIENT_ID,
} from '@lysties/auth/data-access';
import { ErrorHandlerService, WINDOW_TOKEN } from '@lysties/common/browser';
import {
  API_CONTENT_TYPE_TOKEN,
  API_ROOT_TOKEN,
  API_VERSION_TOKEN,
  httpBaseHeadersInterceptor,
  httpBaseUrlInterceptor,
  httpErrorInterceptor,
  httpLocaleInterceptor,
} from '@lysties/common/http';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_DARK_ID,
  GOOGLE_MAPS_DEFAULT_ID,
} from '@lysties/maps/data-access';

import { appRoutes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideNgIconLoader } from '@ng-icons/core';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideClientHydration(withNoHttpTransferCache()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      appRoutes,
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
    ),
    provideHttpClient(
      withFetch(),
      withJsonpSupport(),
      withInterceptors([
        httpLocaleInterceptor,
        authInterceptor,
        httpBaseHeadersInterceptor,
        httpBaseUrlInterceptor,
        httpErrorInterceptor,
      ]),
    ),
    provideNgIconLoader((name) => {
      const http = inject(HttpClient);
      return http.get(`/assets/svg/${name}.svg`, {
        responseType: 'text',
      });
    }),
    {
      provide: WINDOW_TOKEN,
      useFactory: () => {
        if (typeof window !== 'undefined') {
          return window;
        }

        return {
          matchMedia: () => ({
            removeEventListener: () => true,
            addEventListener: () => true,
            matches: true,
          }),
          location: {
            origin:
              process.env['NX_ENVIRONMENT'] === 'production'
                ? 'https://lysties.com'
                : 'https://staging.lysties.com/',
            href: '',
          },
        };
      },
    },
    {
      provide: API_CONTENT_TYPE_TOKEN,
      useValue: process.env['NX_API_CONTENT_TYPE'],
    },
    { provide: API_ROOT_TOKEN, useValue: process.env['NX_API_ROOT'] },
    { provide: API_VERSION_TOKEN, useValue: process.env['NX_API_VERSION'] },
    {
      provide: AUTH_ACCESS_TOKEN_KEY,
      useValue: process.env['NX_AUTH_ACCESS_TOKEN_KEY'],
    },
    {
      provide: AUTH_REFRESH_TOKEN_KEY,
      useValue: process.env['NX_AUTH_REFRESH_TOKEN_KEY'],
    },
    {
      provide: GOOGLE_ACCOUNTS_CLIENT_ID,
      useValue: process.env['NX_GOOGLE_ACCOUNTS_CLIENT_ID'],
    },
    {
      provide: GOOGLE_MAPS_API_KEY,
      useValue: process.env['NX_GOOGLE_MAPS_API_KEY'],
    },
    {
      provide: GOOGLE_MAPS_DEFAULT_ID,
      useValue: process.env['NX_GOOGLE_MAPS_DEFAULT_ID'],
    },
    {
      provide: GOOGLE_MAPS_DARK_ID,
      useValue: process.env['NX_GOOGLE_MAPS_DARK_ID'],
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => true,
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
