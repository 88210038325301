import { Inject, Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';
import { WINDOW_TOKEN } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends SentryErrorHandler {
  constructor(@Inject(WINDOW_TOKEN) private window: Window) {
    super({ logErrors: false, showDialog: false });
  }

  override handleError(error: unknown): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const message = (error as Error).message;

    if (chunkFailedMessage.test(message)) {
      this.window.location.reload();
    } else {
      super.handleError(error);
    }
  }
}
