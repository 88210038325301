import { Route } from '@angular/router';

import { authGuard } from '@lysties/auth/data-access';
import { HomeComponent } from '@lysties/pages/home';
import {
  AuthLayoutComponent,
  BaseLayoutComponent,
  SimpleLayoutComponent,
} from '@lysties/shared/layout';

import { appResolver } from './app.resolver';

export const appRoutes: Route[] = [
  {
    path: '',
    component: BaseLayoutComponent,
    resolve: { app: appResolver },
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'about',
        loadComponent: () =>
          import('@lysties/pages/about').then((c) => c.AboutUsComponent),
      },
      {
        path: 'businesses',
        loadComponent: () =>
          import('@lysties/businesses/search-results').then(
            (c) => c.BusinessSearchResultsComponent,
          ),
      },
      {
        path: 'businesses/:slug',
        loadComponent: () =>
          import('@lysties/businesses/details').then(
            (c) => c.BusinessDetailsComponent,
          ),
        children: [
          {
            path: 'photos',
            loadComponent: () =>
              import('@lysties/businesses/photos').then(
                (c) => c.BusinessPhotosComponent,
              ),
          },
          {
            path: 'photos/add',
            loadComponent: () =>
              import('@lysties/businesses/add-photos').then(
                (c) => c.AddBusinessPhotosComponent,
              ),
            canActivate: [authGuard],
          },
          {
            path: 'reviews/add',
            loadComponent: () =>
              import('@lysties/reviews/add').then((c) => c.ReviewAddComponent),
            canActivate: [authGuard],
          },
        ],
      },
      {
        path: 'users/:id',
        loadChildren: () =>
          import('@lysties/users/profile').then((c) => c.ProfileModule),
      },
      {
        path: 'settings',
        canActivate: [authGuard],
        loadComponent: () =>
          import('@lysties/users/settings').then(
            (c) => c.UserSettingsComponent,
          ),
        children: [
          {
            path: 'profile',
            loadComponent: () =>
              import('@lysties/users/settings').then(
                (c) => c.UserPublicProfileComponent,
              ),
          },
          {
            path: 'account',
            loadComponent: () =>
              import('@lysties/users/settings').then(
                (c) => c.UserAccountComponent,
              ),
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'signup',
        loadComponent: () =>
          import('@lysties/auth/sign-up').then((c) => c.SignUpComponent),
      },
      {
        path: 'signup/confirm/:sub/:token',
        loadComponent: () =>
          import('@lysties/auth/sign-up').then((c) => c.SignUpComponent),
      },
      {
        path: 'signin',
        loadComponent: () =>
          import('@lysties/auth/sign-in').then((c) => c.SignInComponent),
      },
      {
        path: 'signin/confirm/:sub/:token',
        loadComponent: () =>
          import('@lysties/auth/sign-in').then((c) => c.SignInComponent),
      },
    ],
    resolve: { app: appResolver },
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    children: [
      {
        path: 'contact',
        loadComponent: () =>
          import('@lysties/helpdesk/contact').then((c) => c.ContactComponent),
      },
      {
        path: 'privacy',
        loadComponent: () =>
          import('@lysties/pages/privacy').then(
            (c) => c.PrivacyPolicyComponent,
          ),
      },
      {
        path: 'tos',
        loadComponent: () =>
          import('@lysties/pages/tos').then((c) => c.TermsOfServiceComponent),
      },
    ],
    resolve: { app: appResolver },
  },
  {
    path: '**',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@lysties/pages/not-found').then((c) => c.NotFoundComponent),
      },
    ],
    resolve: { app: appResolver },
  },
];
